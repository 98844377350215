import React from "react";
import contents, { lang } from "../scripts/config";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Donate from "./Donate";
import LanguageDropdown from "./LanguageDropdown";
import "../styles/Navbar.css";

const NavbarComponent = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      sticky="top"
      style={{ backgroundColor: "#ff9907" }}
      // bg="#ff9907"
      variant="light"
      className="fade-in"
    >
      <Navbar.Brand>
        <Link to="/">
          <div style={{ width: 150, height: 30 }}>
            <img
              style={{
                maxWidth: "100%"
              }}
              src={contents.Navbar.brandImg.src}
              alt={contents.Navbar.brandImg.alt}
            />
          </div>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {contents.Navbar.options.map((option, i) => (
            <Nav.Item key={i}>
              <Link to={option.href}>{option.title[lang]}</Link>
            </Nav.Item>
          ))}
        </Nav>
        <Nav>
          <LanguageDropdown />
        </Nav>
        <Nav>
          <Nav.Item>
            <Donate />
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
