import React, { Component } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import contents, { lang } from "../scripts/config";
import "../styles/News.css";

class News extends Component {
  render() {
    const { News } = contents;
    const { reports, articles, events } = News;

    return (
      <div className="news-layout">
        {reports.map((report, i) => (
          <div key={i}>
            <Row>
              <Col lg={5}>
                <iframe
                  style={{ width: "100%", height: 360 }}
                  src={report.videoSrc}
                  title={report.videoSrc}
                  frameBorder="0"
                  scrolling="no"
                />
              </Col>
              <Col lg={6}>
                <h1>{report.title[lang]}</h1>
                <p>{report.description[lang]}</p>
                <div className="link-paragraph">
                  {News.viewMore[lang]}{" "}
                  <a href={report.link} target="_blank" rel="noopener noreferrer">{News.here[lang]}</a>.
                </div>
              </Col>
            </Row>
            <hr />
          </div>
        ))}
        <Row>
          {articles.map((article, i) => (
            <Col sm={6} md={4} lg={3} style={{ marginBottom: 10 }}>
              <Card style={{ width: "100%", margin: "3px 0 3px 0" }} key={i}>
                <Card.Img variant="top" src={article.img} alt="Article image" />
                <Card.Body>
                  <Card.Title>{article.title[lang]}</Card.Title>
                  <a href={article.link} target="_blank" rel="noopener noreferrer">
                    <Button variant="warning" style={{ width: "100%", margin: "5px 0 5px 0" }}>{News.viewMore[lang]}</Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <hr />
        <br />
        {events.map((event, i) => (
          <div key={i}>
            <Row>
              <Col lg={6}>
                <div className="announcement-paragraph">
                  {event.description[lang]}{" "}
                  <a href={event.link} target="_blank" rel="noopener noreferrer">{News.here[lang]}</a>.
                </div>
              </Col>
              <Col lg={5}>
                <div
                  className="news-img"
                  style={{
                    background: `url("${event.img}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}
                />
              </Col>
            </Row>
            <hr />
          </div>
        ))}
      </div>
    );
  }
}

export default News;
