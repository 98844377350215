import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/app";
import "firebase/firestore";
// import YouTube from "../components/YouTube";
import Socials from "../components/Socials";
import Footer from "../components/Footer";
import ProjectCard from "../components/ProjectCard";
import contents, { lang } from "../scripts/config";
import GalleryHome from '../components/GalleryComponent';
import "../styles/Home.css";
import DonateModal from "../components/Donate";
import FadeIn from "react-fade-in";

class Home extends Component {
  state = {
    projects: []
  };

  componentDidMount = async () => {
    const snapshot = await firebase
      .firestore()
      .collection("projects")
      .orderBy('order')
      .limit(3)
      .get();

    this.setState({ projects: snapshot.docs.map(doc => doc) });
  };

  render() {
    const projects = this.state.projects;
    const incomingId = this.props.match.params && this.props.match.params['projectId'] ? this.props.match.params['projectId'] : '-123';

    return (
      <div>
        <FadeIn delay={700} transitionDuration={750}>
          <div className="home-projects-container">
            <h1 className="headline" style={{ marginBottom: "8vh" }}>{contents.About.oneLineHeader[lang]}</h1>
            <Row>
              {projects && projects[0] &&
                <Col lg={4}>
                  <ProjectCard
                    project={projects[0].data()}
                    projectId={projects[0].id}
                    key={projects[0].id}
                    home={true}
                    showModal={projects[0].id === incomingId}
                  />
                </Col>
              }
              {projects.map((project, i) => i ?
                <Col lg={4}>
                  <ProjectCard
                    project={project.data()}
                    projectId={project.id}
                    key={project.id}
                    showModal={project.id === incomingId}
                    home={true}
                    smallOverview={true}
                  />
                </Col> : <span />
              )}
            </Row>
          </div>

          <Socials />
          <div style={{ backgroundColor: "rgba(255, 200, 140, 0.8)" }}>
            <h1 className="headline" style={{ padding: 15, paddingBottom: 0 }}>Photo Gallery</h1>
            <div style={{ padding: "5px 30px 30px 30px" }}><GalleryHome /></div>
          </div>

          {this.props.donate && <DonateModal button={false} display={true}/>}

          <Footer />
        </FadeIn>
      </div>
    );
  }
}

export default Home;
