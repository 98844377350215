import React from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import contents, { lang } from "../scripts/config";
import "../styles/Donate.css";

class DonateModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: !!props.display
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (e) => {
    if (e) e.stopPropagation();
    this.setState({ show: true });
  };

  render() {
    const {
      donate,
      donationTitle,
      projectSupport,
      bankDetails,
      bankDonationTitle
    } = contents.Donate;
    return (
      <>
        {
          this.state.show === false && this.props.display
        }

        {
          this.props.button === false ?
            <span /> :
            <Button variant="success" onClick={this.handleShow} style={this.props.style}>
              {donate[lang]}
            </Button>
        }

        <Modal
          className="donation-modal"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{donationTitle[lang]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th>PayPal</th>
                  <th>ePay</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <PayPalDonationForm />
                  </td>
                  <td>
                    <EPayDonationForm />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ textAlign: "left" }}>
                    <b>{bankDonationTitle[lang]}</b> <br />
                    <pre>{bankDetails[lang]}</pre>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Link to="/projects">
                      <Button
                        variant="warning"
                        onClick={() => {
                          this.setState({ show: false });
                        }}
                      >
                        {projectSupport[lang]}
                      </Button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class EPayDonationForm extends React.Component {
  state = {
    paymentAmount: "10"
  };

  handleAmountChange = event => {
    this.setState({ paymentAmount: event.target.value });
  };

  render() {
    const { viaEPay } = contents.Donate;
    return (
      <Form method="POST" action="https://www.epay.bg/v3main/paylogin">
        <Form.Control
          className="epay-amount-input"
          type="number"
          name="TOTAL"
          value={this.state.paymentAmount}
          onChange={this.handleAmountChange}
          size="5"
        />
        {` `}
        <Button
          variant="warning"
          className="epay-button"
          type="submit"
          name="BUTTON:EPAYNOW"
        >
          {viaEPay[lang]}
        </Button>
        <Form.Control type="hidden" name="MIN" value="4692867713" />
        <Form.Control
          type="hidden"
          name="DESCR"
          value="Дарение за Фондация Усмивка за Африка"
        />
        <Form.Control
          type="hidden"
          name="URL_OK"
          value="https://www.epay.bg/v3main/front?p=thanks"
        />
        <Form.Control
          type="hidden"
          name="URL_CANCEL"
          value="https://www.epay.bg/v3main/front?p=cancel"
        />
        <Form.Control type="hidden" name="ENCODING" value="utf8" />
        <Form.Control
          type="hidden"
          name="CHECKSUM"
          value="622731a0783b416ad67ff0211fec25e152e152ee"
        />
      </Form>
    );
  }
}

const PayPalDonationForm = () => (
  <form
    className="ffb-id-1rljv4jh fg-text-light fg-wow flash"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
    target="_top"
    data-fg-wow="flash"
    data-wow-duration="10s"
    style={{
      visibility: "visible",
      animationDuration: "5s",
      animationName: "flash",
      marginTop: 5
    }}
  >
    <input type="hidden" name="cmd" defaultValue="_donations" />
    <input
      type="hidden"
      name="business"
      defaultValue="office@smileforafrica.eu"
    />
    <input type="hidden" name="lc" defaultValue="BG" />
    <input type="hidden" name="item_name" defaultValue="SMILE FOR AFRICA" />
    <input type="hidden" name="no_note" defaultValue={0} />
    <input type="hidden" name="currency_code" defaultValue="EUR" />
    <input
      type="hidden"
      name="bn"
      defaultValue="PP-DonationsBF:btn_donate_SM.gif:NonHostedGuest"
    />
    <input
      type="image"
      src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
      border={0}
      name="submit"
      alt="Donate"
    />
    <img
      alt="Donate"
      border={0}
      src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
      width={1}
      height={1}
    />
  </form>
);

export default DonateModal;
