import React from "react";
import Gallery from "react-grid-gallery";

const images = {
  "GHANA-2019": [
    "IMG_20190403_083532.jpg",
    "IMG_20190403_083603.jpg",
    "IMG_20190403_114034.jpg",
    "IMG_20190403_150718.jpg",
    "IMG_20190403_150745.jpg",
    "IMG_20190403_205146.jpg",
    "IMG_20190404_094611.jpg",
    "IMG_20190404_110030.jpg",
    "IMG_20190404_141143.jpg",
    "IMG_20190404_151655.jpg",
    "IMG_20190404_152349.jpg",
    "IMG_20190404_153153_1.jpg",
    "IMG_20190404_163032.jpg",
    "IMG_20190404_163050.jpg",
    "IMG_20190404_163240.jpg",
    "IMG_20190404_171204.jpg",
    "IMG_20190404_171220.jpg",
    "IMG_20190404_175325.jpg",
    "IMG_20190404_180618.jpg",
    "IMG_20190404_181540.jpg",
    "IMG_20190404_183913.jpg",
    "IMG_20190405_165531.jpg",
    "IMG_20190405_165610.jpg",
    "IMG_20190405_202218.jpg",
    "IMG_20190406_091307.jpg",
    "IMG_20190406_103943.jpg",
    "IMG_20190406_104054.jpg",
    "IMG_20190406_134826.jpg",
    "IMG_20190406_155850.jpg",
    "IMG_20190406_160036.jpg",
    "IMG_20190406_183029.jpg",
    "IMG_20190406_201947.jpg",
    "IMG_20190406_202057.jpg",
    "IMG_20190407_083337.jpg",
    "IMG_20190407_083632.jpg",
    "IMG_20190407_115900.jpg",
    "IMG_20190407_115940.jpg",
    "IMG_20190407_120033.jpg",
    "IMG_20190407_120047.jpg",
    "IMG_20190407_120417.jpg",
    "IMG_20190407_121440.jpg",
    "IMG_20190407_123056.jpg",
    "IMG_20190407_165101.jpg",
    "IMG_20190407_165130.jpg",
    "IMG_20190407_165227.jpg",
    "IMG_20190407_165656.jpg",
    "IMG_20190407_165729.jpg",
    "IMG_20190407_182322.jpg",
    "IMG_20190408_080900.jpg",
    "IMG_20190408_131726.jpg",
    "IMG_20190408_161706.jpg",
    "IMG_20190408_161801.jpg",
    "IMG_20190408_161902.jpg",
    "IMG_20190408_161905.jpg",
    "IMG_20190408_162011.jpg",
    "IMG_20190408_162250.jpg",
    "IMG_20190408_162328.jpg",
    "IMG_20190408_162429.jpg",
    "IMG_20190408_162615.jpg",
    "IMG_20190408_162656_1.jpg",
    "IMG_20190408_162658.jpg",
    "IMG_20190408_162753.jpg",
    "IMG_20190408_163050.jpg",
    "IMG_20190408_163629.jpg",
    "IMG_20190408_163744.jpg",
    "IMG_20190408_163747.jpg",
    "IMG_20190408_163814.jpg",
    "IMG_20190408_163847.jpg",
    "IMG_20190408_163904.jpg",
    "IMG_20190408_170323.jpg",
    "IMG_20190409_131325.jpg",
    "IMG_20190409_131333.jpg",
    "IMG_20190409_131334.jpg",
    "IMG_20190409_170308.jpg",
    "IMG_20190409_170323.jpg",
    "IMG_20190409_170334.jpg",
    "IMG_20190409_171049.jpg",
    "IMG_20190409_171104.jpg",
    "IMG_20190409_173553.jpg",
    "IMG_20190409_193127.jpg",
    "IMG_20190410_073908.jpg",
    "IMG_20190410_083517.jpg",
    "IMG_20190410_160624.jpg",
    "IMG_20190410_161357.jpg",
    "IMG_20190410_161401.jpg",
    "IMG_20190410_161421.jpg",
    "IMG_20190410_161430.jpg",
    "IMG_20190410_161458.jpg",
    "IMG_20190410_161742.jpg",
    "IMG_20190411_052808.jpg",
    "IMG_20190411_053222.jpg",
    "IMG_20190411_054843.jpg",
    "IMG_20190411_062019.jpg",
    "IMG_20190411_075507.jpg",
    "IMG_20190411_082753.jpg",
    "IMG_20190412_103702.jpg",
    "IMG_20190412_130622.jpg",
    "IMG_20190412_153557.jpg",
    "IMG_20190413_090719.jpg",
    "IMG_20190413_105050.jpg",
    "IMG_20190414_061510.jpg",
    "IMG_20190414_061621.jpg",
    "IMG_20190414_061635.jpg",
    "IMG_20190414_141105.jpg",
    "IMG_20190414_143105.jpg",
    "IMG_20190414_145726.jpg",
    "IMG_20190414_160138.jpg",
    "IMG_20190414_181925.jpg",
    "IMG_20190414_215931.jpg",
    "IMG_20190414_220025.jpg",
    "IMG_20190415_075455.jpg",
    "IMG_20190415_075715.jpg",
    "IMG_20190415_084143.jpg",
    "IMG_20190415_104447.jpg",
    "IMG_20190415_174251.jpg",
    "IMG_20190415_204913.jpg",
    "IMG_20190416_085156.jpg",
    "IMG_20190416_113443.jpg",
    "IMG_20190416_145910.jpg",
    "IMG_20190416_150129.jpg",
    "IMG_20190417_084418.jpg",
    "IMG_20190417_084453.jpg",
    "IMG_20190417_084601.jpg",
    "IMG_20190417_084618.jpg",
    "IMG_20190417_084619.jpg",
    "IMG_20190417_084916.jpg"
  ],
  "GHANA-2017": [
    "17264477_10209370243995518_5908816784589041392_n.jpg",
    "17309899_10209355151058204_1293214006850384734_o.jpg",
    "17390801_10209355210859699_8386810384538322575_o.jpg",
    "17424837_10209381692081713_5551592295148752786_n.jpg",
    "17434896_10209365266591086_7589651379839225834_o.jpg",
    "17435871_10209355209699670_2064135748989233524_o.jpg",
    "17436278_10209356012079729_287551828499413249_o.jpg",
    "17457775_10209365279191401_2882562462279919356_n.jpg",
    "17458190_10209370246395578_6116149284781999475_n.jpg",
    "17458357_10209379565588552_2411346663258695777_n.jpg",
    "17492332_10209355212619743_1509274567305826146_o.jpg",
    "17493028_10209372545653058_1391322893709186210_o.jpg",
    "17498508_10209357447955625_1258231749764841298_n.jpg",
    "17498829_10209382400819431_4107599828066135673_n.jpg",
    "17498918_10209370242315476_2121264787787703243_n.jpg",
    "17498968_10209365277671363_9132493075890202623_n.jpg",
    "17499234_10209381328832632_7967529923769548058_n.jpg",
    "17504450_10209388850820677_8964608353167178781_o.jpg",
    "17504491_10209370807729611_1770230935871975517_o.jpg",
    "17504930_10209370587884115_3000866859026588669_o.jpg",
    "17505066_10209387864036008_1408010642926472155_o.jpg",
    "17522690_10209381691121689_7582496548223944349_n.jpg",
    "17522731_10209382401059437_719045450246129909_n.jpg",
    "17522853_10209381327992611_2609322539753886324_n.jpg",
    "17522969_10209365279511409_8186908402541370117_n.jpg",
    "17523584_10209486395459232_7547121537532831837_n.jpg",
    "17545149_10209417103606979_2139956531099719880_o.jpg",
    "17545365_10209380417289844_2763755839888701491_o.jpg",
    "17553908_10209411726272549_1604716930130181376_n.jpg",
    "17554071_10209394059990903_6997241976914098802_n.jpg",
    "17554090_10209405026265053_6471644845220361224_n.jpg",
    "17554115_10209411726512555_3672189900923615449_n.jpg",
    "17554352_10209394057630844_1717727493231575497_n.jpg",
    "17622101_10209417116847310_8167989742397170016_o.jpg",
    "17626232_10209429120907404_6765024575948721892_n.jpg",
    "17626364_10209437754763245_1078278373806570048_n.jpg",
    "17630141_10209405025065023_6122456097101351942_n.jpg",
    "18033835_10209634074351112_1579463372080771569_n.jpg",
    "18058039_10209642160993273_4600561269332839044_n.jpg",
    "20170417_105028.jpg",
    "20170417_105143.jpg",
    "20170418_175005.jpg",
    "Accra 1.jpg",
    "Accra.jpg",
    "bath 1.jpg",
    "bath 2.jpg",
    "IMG_20170406_174506.jpg",
    "_DSC0041.jpg",
    "_DSC0045.jpg",
    "_DSC0053.jpg",
    "_DSC0081.jpg",
    "_DSC0082.jpg",
    "_DSC0084.jpg",
    "_DSC0094.jpg",
    "_DSC0103.jpg",
    "_DSC0104.jpg",
    "_DSC0140.jpg",
    "_DSC0187.jpg",
    "_DSC0261.jpg",
    "_DSC0276.jpg",
    "_DSC0392.jpg",
    "_DSC0464.jpg",
    "_DSC0468.jpg",
    "_DSC0479.jpg",
    "_DSC0482.jpg",
    "_DSC9457.jpg",
    "_DSC9512.jpg",
    "_DSC9517.jpg",
    "_DSC9519.jpg",
    "_DSC9570.jpg",
    "_DSC9597.jpg",
    "_DSC9614.jpg",
    "_DSC9641.jpg",
    "_DSC9693.jpg",
    "_DSC9711.jpg",
    "_DSC9713.jpg",
    "_DSC9721.jpg",
    "_DSC9735.jpg",
    "_DSC9742.jpg",
    "_DSC9781.jpg",
    "_DSC9782.jpg",
    "_DSC9796.jpg",
    "_DSC9809.jpg",
    "_DSC9810.jpg",
    "_DSC9861.jpg",
    "_DSC9876.jpg",
    "_DSC9894.jpg",
    "_DSC9946.jpg",
    "_DSC9949.jpg",
    "_DSC9953.jpg",
    "_DSC9960.jpg",
    "_DSC9962.jpg",
    "_DSC9965.jpg",
    "_DSC9968.jpg",
    "_DSC9976.jpg",
    "_DSC9982.jpg"
  ],
  "UGANDA-2019": [
    "IMG_20190418_120930.jpg",
    "IMG_20190418_121025.jpg",
    "IMG_20190419_174013.jpg",
    "IMG_20190421_110822.jpg",
    "IMG_20190421_163049.jpg",
    "IMG_20190421_163101_1.jpg",
    "IMG_20190421_163128.jpg",
    "IMG_20190421_163325.jpg",
    "IMG_20190421_163329.jpg",
    "IMG_20190421_180050.jpg",
    "IMG_20190421_180735.jpg",
    "IMG_20190421_222303.jpg",
    "IMG_20190422_111908_2.jpg",
    "IMG_20190422_111920.jpg",
    "IMG_20190422_111931.jpg",
    "IMG_20190422_111935.jpg",
    "IMG_20190422_111936.jpg",
    "IMG_20190422_111946.jpg",
    "IMG_20190422_112122.jpg",
    "IMG_20190422_112144.jpg",
    "IMG_20190422_112302.jpg",
    "IMG_20190422_112323.jpg",
    "IMG_20190422_112325.jpg",
    "IMG_20190422_112410.jpg",
    "IMG_20190422_112500.jpg",
    "IMG_20190422_112553.jpg",
    "IMG_20190422_112652.jpg",
    "IMG_20190422_115449.jpg",
    "IMG_20190422_115453.jpg",
    "IMG_20190422_122520.jpg",
    "IMG_20190422_122526.jpg",
    "IMG_20190422_122535.jpg",
    "IMG_20190422_122718.jpg",
    "IMG_20190422_122933.jpg",
    "IMG_20190422_123742.jpg",
    "IMG_20190422_124358.jpg",
    "IMG_20190422_124610.jpg",
    "IMG_20190422_124619.jpg",
    "IMG_20190422_124636.jpg",
    "IMG_20190422_131328.jpg",
    "IMG_20190422_135830.jpg",
    "IMG_20190422_142023.jpg",
    "IMG_20190422_142033.jpg",
    "IMG_20190422_142050.jpg",
    "IMG_20190422_142105.jpg",
    "IMG_20190422_142519.jpg",
    "IMG_20190422_142601.jpg",
    "IMG_20190422_143336.jpg",
    "IMG_20190422_143358.jpg",
    "IMG_20190422_145110.jpg",
    "IMG_20190422_150106.jpg",
    "IMG_20190422_150153.jpg",
    "IMG_20190422_150224.jpg",
    "IMG_20190422_151415.jpg",
    "IMG_20190422_151417.jpg",
    "IMG_20190422_151447_1.jpg",
    "IMG_20190422_151641.jpg",
    "IMG_20190422_151744.jpg",
    "IMG_20190422_163711.jpg",
    "IMG_20190423_121225.jpg",
    "IMG_20190424_110723.jpg",
    "IMG_20190424_111208.jpg",
    "IMG_20190424_111437.jpg",
    "IMG_20190424_111616.jpg",
    "IMG_20190424_111858.jpg",
    "IMG_20190424_112854.jpg",
    "IMG_20190424_112906.jpg",
    "IMG_20190424_113545.jpg",
    "IMG_20190424_113753.jpg",
    "IMG_20190424_113755.jpg",
    "IMG_20190424_113928.jpg",
    "IMG_20190424_114345.jpg",
    "IMG_20190424_114406.jpg",
    "IMG_20190424_114455.jpg",
    "IMG_20190424_114510.jpg",
    "IMG_20190424_122418.jpg",
    "IMG_20190424_122502.jpg",
    "IMG_20190424_122538.jpg",
    "IMG_20190426_161107.jpg",
    "IMG_20190429_141451.jpg",
    "IMG_20190429_141456.jpg",
    "IMG_20190429_142542.jpg",
    "IMG_20190429_142545.jpg",
    "IMG_20190429_142601.jpg",
    "IMG_20190429_142620.jpg",
    "IMG_20190429_142650.jpg",
    "IMG_20190429_142711.jpg",
    "IMG_20190429_142736.jpg",
    "IMG_20190429_142739.jpg",
    "IMG_20190429_142740.jpg",
    "IMG_20190429_142740_2.jpg",
    "IMG_20190429_142741.jpg",
    "IMG_20190429_142741_2.jpg",
    "IMG_20190429_142742_2.jpg",
    "IMG_20190429_142743.jpg",
    "IMG_20190429_142755.jpg",
    "IMG_20190429_142855.jpg",
    "IMG_20190429_142917.jpg",
    "IMG_20190429_142934.jpg",
    "IMG_20190429_144128.jpg",
    "IMG_20190429_144604.jpg",
    "IMG_20190429_144616.jpg",
    "IMG_20190429_144730.jpg",
    "IMG_20190429_144732.jpg",
    "IMG_20190429_145052.jpg",
    "IMG_20190429_145054.jpg",
    "IMG_20190429_145056_1.jpg",
    "IMG_20190429_150418.jpg",
    "IMG_20190429_150423.jpg",
    "IMG_20190429_150447.jpg",
    "IMG_20190429_150459.jpg",
    "IMG_20190429_150519.jpg",
    "IMG_20190430_185313.jpg",
    "IMG_20190430_185326.jpg",
    "IMG_20190501_123502.jpg",
    "IMG_20190501_123835.jpg",
    "IMG_20190501_124300.jpg",
    "IMG_20190501_124323.jpg",
    "IMG_20190501_133942.jpg",
    "IMG_20190501_191001.jpg",
    "IMG_20190501_191022.jpg",
    "IMG_20190501_191031.jpg",
    "IMG_20190501_191825.jpg",
    "IMG_20190502_051234.jpg",
    "IMG_20190502_052637.jpg",
    "IMG_20190502_052741.jpg",
    "IMG_20190502_055536.jpg",
    "IMG_20190502_055800.jpg",
    "IMG_20190502_062158.jpg",
    "IMG_20190502_151423.jpg",
    "IMG_20190502_151453.jpg"
  ]
};

const GalleryPage = ({ match }) => {
  const folder = match.params.folder;
  const gallery = [];
  images[folder].forEach(element => {
    gallery.push({
      src: `https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/gallery%2F${folder}%2F${element}?alt=media&token=ba67e3a0-f337-4f16-a59d-002d5498f3b0`,
      thumbnail: `https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/gallery%2F${folder}%2Fthumbnails%2F${element}?alt=media&token=7ca791e6-1e7a-405c-b63f-c03abca8cd4a`
    });
  });

  return (
    <div style={{ margin: 10 }}>
      <Gallery images={gallery} enableImageSelection={false} />
    </div>
  );
};

export default GalleryPage;
