import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../styles/Friends.css";

class Friends extends Component {
  render() {
    return (
      <div className="friends-page">
        <Row>
          <Col md={6} lg={3}>
            <a
              href="https://anygoal.page.link/smileforafrica"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="https://anygoalanytime.com/img/logo.png" />
            </a>
          </Col>
          <Col md={6} lg={3}>
            <a
              href="https://www.facebook.com/AdventureClub.bg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/friends%2Fadventure-club.jpg?alt=media&token=3a608e8b-ce43-4c62-9eea-66058a59ca1f" />
            </a>
          </Col>
          <Col md={6} lg={3}>
            <a
              href="https://choice.bg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="https://choice.bg/wp-content/uploads/choice.svg" />
            </a>
          </Col>
          <Col md={6} lg={3}>
            <a
              href="http://licatanagrada.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/friends%2Flicata-na-grada.jpg?alt=media&token=ccaba6cf-3d4b-40b3-8ec2-a61a296297cb" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3}>
            <a
              href="https://www.facebook.com/cholakova.mariyana/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/friends%2FMariyana-Cholakova.jpg?alt=media&token=02c0a591-dc16-4751-9458-78bbedeaf062" />
            </a>
          </Col>
          <Col md={6} lg={3}>
            <a
              href="https://www.facebook.com/DIDIKOdesigns"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src="https://storage.googleapis.com/smile-for-africa.appspot.com/friends/didiko.png" />
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Friends;
