import React from "react";
import ReactDOM from "react-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Friends from "./pages/Friends";
import GalleryComponent from "./components/GalleryComponent";
import GalleryPage from "./pages/GalleryPage";
import Contacts from "./pages/Contacts";
import News from "./pages/News";
import Lang from "./pages/Lang";
import Navbar from "./components/Navbar";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/performance';
import "./styles/index.css";
import Projects from "./pages/Projects";

const config = {
  apiKey: "AIzaSyDvGZHYS4RK_09QO7IT2lbheG5A1RmJt0w",
  authDomain: "smile-for-africa.firebaseapp.com",
  databaseURL: "https://smile-for-africa.firebaseio.com",
  projectId: "smile-for-africa",
  storageBucket: "smile-for-africa.appspot.com",
  messagingSenderId: "489396411486",
  appId: "1:489396411486:web:025e7ad419beb481"
};

firebase.initializeApp(config);
firebase.performance();
// const perf = firebase.performance();

ReactDOM.render(
  <>
    <Router>
      <Navbar />
      <div className="content">
        <Route exact path="/" component={Home} />
        <Route exact path="/donate" component={({match}) => <Home donate={true} match={match}/>} />
        <Route path="/projects" component={Projects} />
        <Route exact path="/projects/:projectId" component={Projects} />
        <Route exact path="/home-projects/:projectId" component={Home} />
        <Route path="/news" component={News} />
        <Route path="/about" component={About} />
        <Route path="/friends" component={Friends} />
        <Route exact path="/gallery/:folder" component={GalleryPage} />
        <Route exact path="/gallery" component={GalleryComponent} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/lang/:lang" component={Lang} />
      </div>
    </Router>
  </>,
  document.getElementById("root")
);

// https://bit.ly/CRA-PWA
serviceWorker.unregister();
