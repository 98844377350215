import React, { Component } from "react";
import Cookies from "js-cookie";

class Lang extends Component {
  render() {
    let lang = "bg";
    // TODO: Consider refactoring to work with another languages in the future.
    if (this.props.match.params && this.props.match.params["lang"] === "en")
      lang = "en";
    Cookies.set("lang", lang);
    window.location = "/";
    return <span />;
  }
}

export default Lang;
