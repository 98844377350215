import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Socials from "../components/Socials";
import contents, { lang } from "../scripts/config";
import "../styles/Contacts.css";

class Contacts extends Component {
  render() {
    return (
      <div className="contacts-page">
        <Row>
        {contents.Contacts.map((el, i) => (
          <Col md={4} key={i}>
            <img
              alt={el.img.alt}
              className="action-icon"
              src={el.img.src}
            />
            <br />
            <a href={el.link.href} target="_blank" rel="noopener noreferrer">{el.link.text[lang] || el.link.text}</a>
          </Col>
        ))}
        </Row>
        <iframe
          frameBorder="0"
          style={{ border: 0, height: "50vh", width: "100%" }}
          title="Google Maps iframe"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:EkNCb3VsZXZhcmQgIlNpbWVvbm92c2tvIFNob3NlIDksIDE3MDAgemguZy4gWm9vcGFyaywgU29maWEsIEJ1bGdhcmlhIjASLgoUChIJiZnAmjiEqkARv2r3fPAD0VgQCSoUChIJiXQIpFuBqkAR3pK4FfiL4hw&key=AIzaSyDvGZHYS4RK_09QO7IT2lbheG5A1RmJt0w"
          allowFullScreen
        />
        <hr />
        <Socials />
      </div>
    );
  }
}

export default Contacts;
