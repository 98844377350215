import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Card, Row, Col, ProgressBar, Modal, Button } from "react-bootstrap";
import contents, { lang } from "../scripts/config";
// import Gallery from "react-grid-gallery";
import scriptLoader from "react-async-script-loader";
import Youtube from "./YouTube";
import "../styles/ProjectCard.css";
import Donate from "./Donate";


/* global paypal */
class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
      amount: "10.00",
      collectedMoney: this.props.project.collectedMoney,
      currency: this.props.project.currency,
      showModal: this.props.showModal
    };
  }

  handleClose = () => {
    this.setState({ showModal: false });
    this.props.history.push(this.props.home ? `/` : `/projects`);
  };

  handleShowModal = () => {
    if (this.props.project.thirdPartyPlatform) {
      window.open(this.props.project.thirdPartyPlatform, '_blank');
      return true;
    }

    // this.setState({ showModal: true });
    this.props.history.push(`/${this.props.home ? `home-` : ``}projects/${this.props.projectId}`);
  };

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;

    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }

  payment = () =>
    fetch(
      "https://us-central1-smile-for-africa.cloudfunctions.net/createPayment",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ amount: this.state.amount })
      }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        return data.id;
      });

  onAuthorize = data =>
    fetch(
      "https://us-central1-smile-for-africa.cloudfunctions.net/executePayment",
      {
        method: "POST",
        body: JSON.stringify({
          paymentID: data.paymentID,
          payerID: data.payerID,
          amount: this.state.amount,
          id: this.props.projectId
        })
      }
    ).then(res => {
      this.setState({
        collectedMoney:
          parseFloat(this.state.amount) + this.state.collectedMoney
      });
    });

  handleAmountChange = event => {
    this.setState({ amount: event.target.value });
  };

  render() {
    const { state, payment, onAuthorize, handleShowModal, handleClose } = this;
    const { showButton, amount, collectedMoney, currency, showModal } = state;
    const {
      name,
      totalNeededMoney,
      motto,
      shortDescription,
      longDescriptionUrl,
      imageUrl,
      // images,
      active,
      links,
      video,
      descriptionVideo,
      multipleDonationOptions,
    } = this.props.project;
    const PayButton = multipleDonationOptions ? Donate : paypal.Buttons.driver("react", { React, ReactDOM });
    const {
      completed,
      // projectAmount,
      partnerInformation,
      // euro,
      projectWord
    } = contents.ProjectCard.strings;

    return (
      <>
        <Card
          className={"project-card" + (this.props.smallOverview ? " small-card" : "")}
          onClick={handleShowModal}
        >
          {video && !this.props.smallOverview ? (
            <Card.Header>
              <Youtube height="100%" videoId={video} />
            </Card.Header>
          ) : (
            <Card.Img variant="top" src={imageUrl} />
          )}
          <Card.Body>
            <Card.Title>{name[lang]}</Card.Title>
            <Card.Text style={{ textJustify: "s" }}>
              {this.props.smallOverview ? <i>{shortDescription[lang]}</i> : <>
                {shortDescription[lang]}<b>{motto && motto[lang]}</b>
              </>}
            </Card.Text>
            <div className="bottom-card-content">
              <Card.Text>{
                `${currency}${collectedMoney} / ${currency}${totalNeededMoney}`
              }</Card.Text>
              <ProgressBar
                now={(collectedMoney / totalNeededMoney) * 100}
                label={""}
                striped
                variant="info"
              />
              {!this.props.smallOverview &&
                <Row>
                  {active ? (
                    <>
                      {!multipleDonationOptions &&
                        <Col className="amount-input-col">
                          <input
                            type="number"
                            value={amount}
                            onChange={this.handleAmountChange}
                          />
                        </Col>
                      }
                      <Col>
                        {showButton && (
                          <PayButton
                            style={{ width: "100%" }}
                            env={"sandbox"}
                            createOrder={payment}
                            onApprove={onAuthorize}
                          />
                        )}
                      </Col>
                    </>
                  ) : (
                    <span />
                  )}
                </Row>
              }
              <div style={{ padding: 5 }}></div>
            </div>
          </Card.Body>
        </Card>

        <Modal size="lg" show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{`${projectWord[lang]} "${name[lang]}"`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              {/* {projectAmount[lang]}: {totalNeededMoney} {euro[lang]}. |{" "} */}
              {`€${collectedMoney} / €${totalNeededMoney}`}
            </h5>
            <ProgressBar
              now={(collectedMoney / totalNeededMoney) * 100}
              label={""}
              striped
              variant="info"
            />
            <Row>
              {active ? (
                <>
                  {
                    !multipleDonationOptions &&
                    <Col className="amount-input-col">
                      <input
                        type="number"
                        value={amount}
                        onChange={this.handleAmountChange}
                      />
                    </Col>
                  }
                  <Col>
                    {showButton && (
                      <PayButton
                        style={{ width: "100%" }}
                        env={"sandbox"}
                        createOrder={payment}
                        onApprove={onAuthorize}
                      />
                    )}
                  </Col>
                </>
              ) : (
                <Button
                  style={{ width: "100%", marginBottom: 5 }}
                  disabled
                  variant="success"
                >
                  {completed[lang]}
                </Button>
              )}
            </Row>
            <iframe
              width="100%"
              height="375px"
              style={{ border: 0 }}
              title="Описанието на проекта трябва да се покаже тук..."
              src={longDescriptionUrl[lang]}
            />
            {links && (
              <ul>
                <>
                  <div>{partnerInformation[lang]}:</div>
                  {links.map((link, key) => (
                    <li key={key}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.text}
                      </a>
                    </li>
                  ))}
                </>
              </ul>
            )}
            {descriptionVideo && (
              <Youtube height="300" videoId={descriptionVideo} />
            )}
            {/* <Gallery images={images} enableImageSelection={false} /> */}
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      </>
    );
  }
}

export default withRouter(
  scriptLoader("https://www.paypalobjects.com/api/checkout.js")(ProjectCard)
);
