import React from "react";
import YouTube from "react-youtube";
import "../styles/Player.css";

export default class Example extends React.Component {
  render() {
    const opts = {
      height: this.props.height,
      width: "100%",
      playerVars: {
        autoplay: 0
      }
    };

    return <YouTube className="player" videoId={this.props.videoId} opts={opts} />;
  }
}
