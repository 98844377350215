import React from "react";
import contents, { lang } from "../scripts/config";

const Footer = () => {
  return (
    <div style={{
      padding: 10,
      backgroundColor: "#5a0000",
      color: "white",
      textAlign: "center",
      fontSize: 12
    }}>
      {contents.Footer.content[lang]}{" "}
      Website by {" "}
      <a
        href="https://www.linkedin.com/in/radostin-cholakov-bb4422146/"
        style={{
          color: "white",
          fontWeight: "bold",
        }}
      >Radi Cho</a>
    </div>
  );
}

export default Footer;
