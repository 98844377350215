import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/Gallery.css";

const GalleryComponent = () => (
  <div className="gallery-page">
    <Row>
      <Col lg={4} style={{ marginBottom: 5 }}>
        <Link to="/gallery/UGANDA-2019">
          <div
            className="gallery-folder"
            style={{
              background: `url("https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/gallery%2FUGANDA-2019%2Fcover.jpg?alt=media&token=5b6fbda9-96b5-4a6c-acd7-5e232540a115")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              margin: 5
            }}
          >
            Uganda 2019
          </div>
        </Link>
      </Col>
      <Col lg={4} style={{ marginBottom: 5 }}>
        <Link to="/gallery/GHANA-2019">
          <div
            className="gallery-folder"
            style={{
              background: `url("https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/gallery%2FGHANA-2019%2Fcover.jpg?alt=media&token=5b6fbda9-96b5-4a6c-acd7-5e232540a115")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              margin: 5
            }}
          >
            Ghana 2019
          </div>
        </Link>
      </Col>
      <Col lg={4} style={{ marginBottom: 5 }}>
        <Link to="/gallery/GHANA-2017">
          <div
            className="gallery-folder"
            style={{
              background: `url("https://firebasestorage.googleapis.com/v0/b/smile-for-africa.appspot.com/o/gallery%2FGHANA-2017%2Fcover.jpg?alt=media&token=5b6fbda9-96b5-4a6c-acd7-5e232540a115")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              margin: 5
            }}
          >
            Ghana 2017
          </div>
        </Link>
      </Col>
    </Row>
  </div>
);
export default GalleryComponent;
