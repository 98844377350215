import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { lang, languageSpecs } from "../scripts/config";
import Cookies from "js-cookie";
import "../styles/Navbar.css";

export default class LanguageDropdown extends React.Component {
  render() {
    const allLanguages = Object.keys(languageSpecs);

    return (
      <>
        <DropdownButton
          variant="warning"
          title={
            <span>
              <img alt={lang} src={languageSpecs[lang].flagImg} /> {lang}
            </span>
          }
        >
          {allLanguages.map((option, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                Cookies.set("lang", option);
                window.location.reload();
              }}
            >
              {languageSpecs[option].name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </>
    );
  }
}
