import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import contents, { lang } from "../scripts/config";
import "../styles/About.css";

let isMounted = false;
class About extends Component {
  constructor() {
    super();
    this.state = {
      triangleBottomPosition: 15
    };
    this.layoutRef = React.createRef();
  }

  componentDidMount() {
    isMounted = true;
    setInterval(() => {
      if (isMounted)
        this.setState({
          triangleBottomPosition:
            this.state.triangleBottomPosition === 15 ? 16 : 15
        });
    }, 1000);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  render() {
    const { triangleBottomPosition } = this.state;
    const rows = contents.About.informationBlocks;

    return (
      <>
        <div className="greeting-container">
          <div className="greeting-title">
            {contents.About.header[lang].split("\n").map(el => (
              <div id="title-text">{el}</div>
            ))}
          </div>
          <div
            className="navigation-arrow-container"
            style={{ bottom: triangleBottomPosition + "vh" }}
            onClick={() => {
              window.scrollTo({
                top: this.layoutRef.current.offsetTop,
                left: 0,
                behavior: "smooth"
              });
            }}
          >
            <div>
              <i className="triangle-icon" />
            </div>
          </div>
        </div>
        <div ref={this.layoutRef} className="about-layout">
          {rows.map((row, i) => (
            <Row key={i}>
              <Col lg={4}>
                <div
                  className="about-img"
                  style={{
                    background: `url("${row.img}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}
                />
              </Col>
              <Col lg={8}>
                <h1>{row.title[lang]}</h1>
                <p>{row.description[lang]}</p>
              </Col>
            </Row>
          ))}
        </div>
      </>
    );
  }
}

export default About;
