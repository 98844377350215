import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/app";
import "firebase/firestore";
import Footer from "../components/Footer";
import ProjectCard from "../components/ProjectCard";
import "../styles/Home.css";

class Projects extends Component {
  state = {
    projects: []
  };

  componentDidMount = async () => {
    const snapshot = await firebase
      .firestore()
      .collection("projects")
      .orderBy('order')
      .get();
    this.setState({ projects: snapshot.docs.map(doc => doc) });
  };

  render() {
    const projects = this.state.projects;
    const incomingId = this.props.match.params && this.props.match.params['projectId'] ? this.props.match.params['projectId'] : '-123';

    return (
      <div>
        <div className="home-projects-container">
          <Row>
            {projects.map((project, i) =>
              <Col lg={3}>
                <ProjectCard
                  project={project.data()}
                  projectId={project.id}
                  key={project.id}
                  showModal={project.id === incomingId}
                />
              </Col>
            )}
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Projects;
